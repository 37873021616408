import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from 'core/constants';
import { LoginRequest } from 'core/models';
import { ExternalConfigService } from 'core/services';
import { Md5 } from 'ts-md5';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'core-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  form: UntypedFormGroup;
  submitAttempted = false;
  @Input() authenticationState: { authenticationInProgress: boolean, authenticationError: boolean, authorizationError: boolean, wSO2Error: boolean, emailNotValidError: boolean, userDisabled: boolean, customerExpired: boolean };
  @Output() sendLogin = new EventEmitter<LoginRequest>();
  @Input() showForgotPasswordOption: boolean = false;
  @Input() showRememberOption: boolean = false;
  @Output() forgotPassword = new EventEmitter<any>();
  @Output() rememberLoginInfo = new EventEmitter<boolean>();
  isPasswordVisible: boolean = false;

  constructor(private externalConfigService: ExternalConfigService,) {
    this.form = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
      password: new UntypedFormControl(null, Validators.required)
    });

    this.form.valueChanges.subscribe(() => {
      this.submitAttempted = false;
    });
  }

  onLoginClicked(): void {
    this.submitAttempted = true;
    let pswd
    if (this.externalConfigService.getExternalConfiguration()['identityManagement'] == "INAPP") {
      const md5 = new Md5();
      pswd = md5.appendStr(this.form.controls.password.value).end();
    } else {
      pswd = this.form.controls.password.value;
    }
    if (this.form.valid) {
      this.sendLogin.emit({ username: this.form.controls.username.value, password: pswd });
    }
  }

  loginButtonAvailable(): boolean {
    return !(this.authenticationState.authenticationInProgress || (this.submitAttempted && !this.authenticationState.authenticationError && !this.authenticationState.authorizationError
      && !this.authenticationState.emailNotValidError));
  }
}
