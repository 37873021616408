import { Injectable } from '@angular/core';
import { AuthStompService } from '../auth-stomp-service/auth-stomp.service';
import { Store, select } from '@ngrx/store';
import { rootReducers, rootSelectors } from 'core/root-store';
import { AuthenticatedUser, ISocketMessage, eventType } from 'core/models';
import { map } from 'rxjs/operators';
import { IndexedDBService, DBTableNames } from 'core/services';
import { INameReference } from 'core/config';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable()
export class OutboxStompService extends RxStomp {
  private handlers: Partial<Record<eventType, (ISocketMessage: ISocketMessage) => void>> = {
    create: this.createHandler,
    update: this.updateHandler,
    delete: this.deleteHandler
  };
  userDetail: AuthenticatedUser;

  constructor(private authStompService: AuthStompService,
    private modelerStore: Store<rootReducers.authenticationReducer.IAuthenticationState>,
    private indexedDB: IndexedDBService,) {
    super();
    this.subscribeAuthenticatedUser();
  }

  subscribeAuthenticatedUser(): void {
    this.modelerStore.pipe(select(rootSelectors.getAuthenticatedUser)).subscribe((user: AuthenticatedUser) => {
      if (this.userDetail?.userId == user.userId) return;
      this.userDetail = user;
      this.subscribeOutboxEvent(user.accountId);
    })
  }

  subscribeOutboxEvent(accountId: string): void {
    const stompSubscription = this.authStompService.watch(`/outbox/${accountId}`);
    stompSubscription
      .pipe(map((message: any) => JSON.parse(message.body)))
      .subscribe((message: ISocketMessage) => {
        this.handlers[message.eventType].call(this, message);
      });
  }

  async createHandler(socketMessage: ISocketMessage) {
    if (socketMessage.data.nameReference) {
      await this.indexedDB.upsertNameReference(this._convertToNameReferenceFormat(socketMessage.data.nameReference));
    } else {
      await this.indexedDB.setToNameReferenceTable(socketMessage.data.nameReferences.map(item => this._convertToNameReferenceFormat(item)));
    }
    this._updateTableSyncStatus();
  }

  async updateHandler(socketMessage: ISocketMessage) {
    await this.indexedDB.upsertNameReference(this._convertToNameReferenceFormat(socketMessage.data.nameReference));
    this._updateTableSyncStatus();
  }

  async deleteHandler(socketMessage: ISocketMessage) {
    await this.indexedDB.removeNameReferenceByIds(socketMessage.data.nameReferenceId ? [socketMessage.data.nameReferenceId] : socketMessage.data.nameReferenceIds);
    this._updateTableSyncStatus();
  }

  private async _updateTableSyncStatus() {
    await this.indexedDB.increaseTableVersion(this.userDetail.accountId, DBTableNames.NameReference);
  }

  private _convertToNameReferenceFormat(source: any): INameReference {
    return {
      id: source.nameReferenceId,
      referenceId: source.referenceId,
      type: source.type,
      name: source.name,
      count: source.count,
      projectId: source.projectId || null,
      entityId: source.entityId || null,
      lastModified: source.lastModified
    } as INameReference;
  }
}
