<!-- <object class="logo" data="assets/images/logo.svg" type="image/svg+xml">
  <img class="logo" src="assets/images/logo.png" />
</object> -->
<svg version="1.1" [style.width]="iconWidth" [style.height]="iconHeight" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
  y="0px" width="100%" viewBox="0 0 364 274" enable-background="new 0 0 364 274" xml:space="preserve">
  <path fill="transparent" opacity="1.000000" stroke="#ffffff" d="
M365.000000,89.000000 
	C365.000000,151.333328 365.000000,213.166672 365.000000,275.000000 
	C243.666672,275.000000 122.333336,275.000000 1.000000,275.000000 
	C1.000000,183.666672 1.000000,92.333336 1.000000,1.000000 
	C122.333336,1.000000 243.666672,1.000000 365.000000,1.000000 
	C365.000000,30.166666 365.000000,59.333332 365.000000,89.000000 
M358.963531,15.337676 
	C358.920135,14.891662 358.876740,14.445647 358.969025,13.171416 
	C358.923798,11.776564 358.878571,10.381711 358.875580,8.487984 
	C358.591827,8.380877 358.308105,8.273771 357.092621,8.030819 
	C241.386215,8.020839 125.679817,8.010443 9.973416,8.007747 
	C8.977917,8.007723 7.982416,8.111403 6.488017,8.124427 
	C6.380900,8.408174 6.273783,8.691920 6.030818,9.907374 
	C6.020839,95.280823 6.010298,180.654266 6.007767,266.027710 
	C6.007738,267.022827 6.111405,268.017944 6.124415,269.511963 
	C6.408148,269.619080 6.691882,269.726227 7.907330,269.969177 
	C123.781219,269.979248 239.655106,269.989655 355.528992,269.992462 
	C356.357025,269.992493 357.185028,269.888672 358.511993,269.875610 
	C358.619110,269.591858 358.726227,269.308105 358.968262,268.391022 
	C358.788422,267.593231 358.608582,266.795441 358.385376,265.911102 
	C358.385376,265.911102 358.427185,265.718018 358.891357,265.077515 
	C358.927399,264.247253 358.994934,263.417023 358.994995,262.586761 
	C358.999817,181.552643 359.000275,100.518532 358.992706,19.484419 
	C358.992615,18.323063 358.888763,17.161716 358.963531,15.337676 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M358.013062,269.833344 
	C357.185028,269.888672 356.357025,269.992493 355.528992,269.992462 
	C239.655106,269.989655 123.781219,269.979248 7.315530,269.755951 
	C6.549379,269.354248 6.363691,269.177704 6.166667,269.013062 
	C6.111405,268.017944 6.007738,267.022827 6.007767,266.027710 
	C6.010298,180.654266 6.020839,95.280823 6.244030,9.315565 
	C6.645726,8.549397 6.822284,8.363701 6.986916,8.166666 
	C7.982416,8.111403 8.977917,8.007723 9.973416,8.007747 
	C125.679817,8.010443 241.386215,8.020839 357.684448,8.244028 
	C358.450623,8.645698 358.636322,8.822238 358.833344,8.986858 
	C358.878571,10.381711 358.923798,11.776564 358.534851,13.585599 
	C357.122650,13.999449 356.144653,13.999117 354.908325,13.640541 
	C353.005951,13.188210 351.363068,13.052269 349.717712,13.007215 
	C342.268677,12.803240 334.469727,11.653341 329.864197,19.919588 
	C329.501343,18.625134 329.079010,17.393604 329.321381,16.310871 
	C329.847656,13.959905 329.342682,13.130221 326.489563,14.065047 
	C270.032593,14.050182 214.039520,14.080099 157.851196,14.032324 
	C157.808945,14.622408 157.961960,15.290185 158.421387,16.099623 
	C159.936310,17.777601 161.103378,19.348743 162.359863,20.844755 
	C173.007034,33.521545 183.750015,46.118763 194.310471,58.867241 
	C210.285995,78.152740 226.137772,97.540726 242.092194,117.229187 
	C243.443848,119.418587 244.745438,121.263962 245.836090,123.477135 
	C243.312469,126.263283 241.701797,124.452423 240.311951,122.794281 
	C231.975006,112.847908 223.670547,102.873749 215.424591,92.851784 
	C204.996887,80.178200 194.651169,67.437195 184.240906,54.749203 
	C175.077484,43.580853 166.132706,32.214748 156.526764,21.439299 
	C153.727585,18.299311 152.469437,12.927212 146.512711,12.950395 
	C111.024323,13.088517 75.535332,13.073494 39.709152,12.924649 
	C37.207249,12.625687 35.042740,12.511963 32.878235,12.398239 
	C32.842255,13.246085 32.806271,14.093932 32.770290,14.941779 
	C35.212570,14.941779 37.654850,14.941779 40.066154,14.951378 
	C40.035183,14.960978 40.054405,14.899038 40.235497,15.204421 
	C42.037292,16.199743 43.613415,17.032452 45.286423,17.554893 
	C52.499458,19.807352 59.785782,21.837849 66.930122,24.285681 
	C68.198074,24.720114 68.918350,26.753025 69.513641,28.012285 
	C50.513237,20.898481 31.373001,16.728323 11.191601,17.075254 
	C11.104379,18.485764 11.010173,19.298607 11.010092,20.111460 
	C11.002210,99.429794 10.996467,178.748138 11.041897,258.066437 
	C11.042680,259.433350 11.666735,260.799927 12.100872,262.348114 
	C12.801220,262.408600 13.400652,262.287628 14.445983,262.098816 
	C17.594578,262.076172 20.297272,262.121399 23.151966,262.403442 
	C24.536055,262.149017 25.768141,261.657837 27.396826,261.098938 
	C28.862270,261.076355 29.931118,261.121521 31.151960,261.403442 
	C32.536064,261.149017 33.768166,260.657837 35.372272,260.099304 
	C36.496151,260.076874 37.248028,260.121765 38.107269,260.436127 
	C40.050529,262.254181 38.022934,269.185333 44.514080,264.937408 
	C145.491913,264.987000 246.006012,264.992432 346.520111,265.006927 
	C348.680084,265.007233 350.840057,265.111115 353.159912,265.449158 
	C355.022736,265.820312 356.725739,265.908997 358.428711,265.997650 
	C358.608582,266.795441 358.788422,267.593231 358.755493,268.833618 
	C358.354279,269.450623 358.177734,269.636322 358.013062,269.833344 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M358.833344,16.000366 
	C358.888763,17.161716 358.992615,18.323063 358.992706,19.484419 
	C359.000275,100.518532 358.999817,181.552643 358.994995,262.586761 
	C358.994934,263.417023 358.927399,264.247253 358.060791,265.271667 
	C356.448792,265.296295 355.667328,265.126770 354.932434,264.493347 
	C354.985962,183.512207 354.991699,102.994965 355.006958,22.477726 
	C355.007385,20.318472 355.111115,18.159239 355.533142,16.000000 
	C356.877563,16.000124 357.855438,16.000244 358.833344,16.000366 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M354.885895,264.957214 
	C355.667328,265.126770 356.448792,265.296295 357.828705,265.591919 
	C358.427185,265.718018 358.385376,265.911102 358.407043,265.954376 
	C356.725739,265.908997 355.022736,265.820312 353.469574,265.380615 
	C354.041565,265.005463 354.463715,264.981354 354.885895,264.957214 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M358.898438,15.669022 
	C357.855438,16.000244 356.877563,16.000124 355.468079,15.668575 
	C355.079865,14.891026 355.123260,14.444906 355.166656,13.998785 
	C356.144653,13.999117 357.122650,13.999449 358.466980,13.999706 
	C358.876740,14.445647 358.920135,14.891662 358.898438,15.669022 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M358.262512,269.854492 
	C358.177734,269.636322 358.354279,269.450623 358.688049,269.150299 
	C358.726227,269.308105 358.619110,269.591858 358.262512,269.854492 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M6.145541,269.262512 
	C6.363691,269.177704 6.549379,269.354248 6.849672,269.688049 
	C6.691882,269.726227 6.408148,269.619080 6.145541,269.262512 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M358.854462,8.737421 
	C358.636322,8.822238 358.450623,8.645698 358.150299,8.311951 
	C358.308105,8.273771 358.591827,8.380877 358.854462,8.737421 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M6.737467,8.145546 
	C6.822284,8.363701 6.645726,8.549397 6.311954,8.849710 
	C6.273783,8.691920 6.380900,8.408174 6.737467,8.145546 
z" />
  <path fill="transparent" opacity="1.000000" stroke="#ffffff" d="
M354.908325,13.640542 
	C355.123260,14.444906 355.079865,14.891026 355.101562,15.668571 
	C355.111115,18.159239 355.007385,20.318472 355.006958,22.477726 
	C354.991699,102.994965 354.985962,183.512207 354.932434,264.493347 
	C354.463715,264.981354 354.041565,265.005463 353.309692,265.098114 
	C350.840057,265.111115 348.680084,265.007233 346.520111,265.006927 
	C246.006012,264.992432 145.491913,264.987000 44.576302,264.670593 
	C44.487942,263.921356 44.801098,263.481995 45.512695,263.025024 
	C48.746628,262.292664 51.582123,261.577942 54.795303,260.915680 
	C56.440220,260.642853 57.736073,260.401306 58.970303,259.979462 
	C73.644417,254.964310 87.889816,249.097015 100.392197,239.058578 
	C107.865997,232.682678 114.989746,226.363007 122.409241,219.876007 
	C122.841415,218.820007 122.977837,217.931320 123.407944,217.019150 
	C124.150497,216.699753 124.599380,216.403854 125.359207,215.960144 
	C127.840004,212.977707 130.050522,210.173141 132.172318,207.302994 
	C139.266769,197.706268 144.609879,187.234085 148.048676,175.448807 
	C148.453796,174.121841 148.643570,173.060730 149.028229,171.634583 
	C149.759857,169.756409 150.296585,168.243256 150.898346,166.486511 
	C150.920029,165.912415 150.876678,165.581940 150.899429,164.937134 
	C150.942154,164.196228 150.918762,163.769638 151.178619,163.152802 
	C151.585709,161.641815 151.709518,160.321091 151.903061,158.677460 
	C152.020172,157.917053 152.067551,157.479538 152.436432,156.830856 
	C152.783081,155.079910 152.808212,153.540131 152.898438,151.669037 
	C152.920135,150.891678 152.876740,150.445648 152.909149,149.647751 
	C153.060242,145.389557 152.961487,141.470657 153.242859,137.579254 
	C154.152466,124.999123 151.762360,112.805725 147.473633,101.226654 
	C138.577713,77.208679 124.277443,56.939877 102.976807,41.659264 
	C101.911102,40.873890 100.983040,40.386757 100.043869,39.930664 
	C100.032753,39.961700 100.094948,39.939823 99.960068,39.642609 
	C98.901802,38.863487 97.978409,38.381580 97.042923,37.927620 
	C97.030815,37.955566 97.087105,37.932259 96.937180,37.618446 
	C87.312668,32.254108 78.172905,26.367191 68.264381,22.400635 
	C59.297096,18.810867 49.490768,17.317043 40.054405,14.899038 
	C40.054405,14.899038 40.035183,14.960978 40.027363,14.647535 
	C40.028545,13.926025 40.037544,13.517958 40.046547,13.109888 
	C75.535332,13.073494 111.024323,13.088517 146.512711,12.950395 
	C152.469437,12.927212 153.727585,18.299311 156.526764,21.439299 
	C166.132706,32.214748 175.077484,43.580853 184.240906,54.749203 
	C194.651169,67.437195 204.996887,80.178200 215.424591,92.851784 
	C223.670547,102.873749 231.975006,112.847908 240.311951,122.794281 
	C241.701797,124.452423 243.312469,126.263283 246.135056,123.339355 
	C247.135223,121.902977 247.625488,120.972160 248.379822,119.939339 
	C249.112076,119.260818 249.580261,118.684303 250.351944,117.966766 
	C251.474533,116.565147 252.293655,115.304558 253.339325,113.970871 
	C253.853012,113.711479 254.013931,113.448074 254.352234,112.966461 
	C255.475769,111.564034 256.295715,110.302704 257.407043,108.914032 
	C258.837128,107.538582 259.975830,106.290474 261.381226,104.918373 
	C261.780823,104.232841 261.913727,103.671295 262.281067,103.022430 
	C262.690430,102.662369 262.865387,102.389618 263.343933,101.974770 
	C264.470245,100.568909 265.292969,99.305145 266.379730,97.939392 
	C267.112000,97.260872 267.580231,96.684334 268.350006,95.968353 
	C269.378815,94.794205 270.106049,93.759499 271.021729,92.488152 
	C272.156586,91.203217 273.103027,90.154922 274.330139,88.966293 
	C274.778198,88.231895 274.945587,87.637825 275.401367,86.919281 
	C276.809021,85.566086 277.928345,84.337357 279.327972,82.968704 
	C279.774200,82.235863 279.940125,81.642929 280.370117,80.947861 
	C281.102814,80.269531 281.571472,79.693352 282.340088,78.977722 
	C283.464386,77.573425 284.288696,76.308586 285.393005,74.927193 
	C286.465149,73.909302 287.257294,73.007965 288.330322,71.966019 
	C288.779358,71.230721 288.947510,70.636047 289.378784,69.940262 
	C290.107971,69.265152 290.574036,68.691162 291.339691,67.978073 
	C292.461304,66.576195 293.283325,65.313400 294.411102,63.909927 
	C298.493774,59.215710 302.270721,54.662170 306.327972,49.968712 
	C306.774200,49.235851 306.940155,48.642918 307.399719,47.920410 
	C308.532928,46.849815 309.372498,45.908783 310.491760,44.846230 
	C311.196533,43.853058 311.621582,42.981407 312.281067,42.022446 
	C312.690460,41.662388 312.865417,41.389637 313.343811,40.974888 
	C314.469208,39.569843 315.291107,38.306801 316.397583,36.922928 
	C317.492432,35.882660 318.302734,34.963219 319.406860,33.913483 
	C320.171509,32.870056 320.642242,31.956926 321.377319,30.941507 
	C322.110565,30.262074 322.579498,29.684929 323.349548,28.968094 
	C324.471344,27.566927 325.292053,26.305450 326.339294,24.970886 
	C326.853027,24.711512 327.013947,24.448109 327.351501,23.967075 
	C328.471588,22.567699 329.288849,21.308840 330.106079,20.049980 
	C334.469727,11.653341 342.268677,12.803240 349.717712,13.007215 
	C351.363068,13.052269 353.005951,13.188210 354.908325,13.640542 
z" />
  <path fill="transparent" opacity="1.000000" stroke="#ffffff" d="
M22.999968,262.166656 
	C20.297272,262.121399 17.594578,262.076172 14.114738,262.033691 
	C12.891713,262.079865 12.445833,262.123260 11.999954,262.166656 
	C11.666735,260.799927 11.042680,259.433350 11.041897,258.066437 
	C10.996467,178.748138 11.002210,99.429794 11.010092,20.111460 
	C11.010173,19.298607 11.104379,18.485764 11.191601,17.075254 
	C31.373001,16.728323 50.513237,20.898481 69.678192,28.312168 
	C71.465942,29.136181 72.711258,29.624790 74.117767,30.405210 
	C75.151176,30.815193 76.023399,30.933365 77.059753,31.351109 
	C78.467781,32.137878 79.711678,32.625072 81.101929,33.422440 
	C83.464043,34.838909 85.679817,35.945206 88.035492,37.331886 
	C88.768784,37.778938 89.362183,37.945606 90.101662,38.422211 
	C97.361526,44.185631 104.839676,49.230797 111.509056,55.182285 
	C123.368912,65.765549 131.573151,79.200844 139.104568,93.301971 
	C139.326050,94.085793 139.609604,94.567551 139.944046,95.376205 
	C140.385498,96.468697 140.776077,97.234299 141.104568,98.301971 
	C141.326050,99.085793 141.609619,99.567543 141.944046,100.376205 
	C142.385498,101.468697 142.776077,102.234299 143.112549,103.390694 
	C144.427856,108.854225 145.797256,113.926971 147.099915,119.355667 
	C147.410995,120.807716 147.788834,121.903809 148.101562,123.331085 
	C148.079865,124.108078 148.123260,124.553879 148.099030,125.375877 
	C148.409821,127.168015 148.788239,128.583954 149.101562,130.331085 
	C149.079880,131.108078 149.123260,131.553879 149.106201,132.343399 
	C148.989883,138.446060 148.934036,144.205002 148.580902,150.135590 
	C148.244629,151.204819 148.205643,152.102402 148.098755,153.392380 
	C147.979965,154.844513 147.929077,155.904221 147.580902,157.135590 
	C147.244629,158.204819 147.205643,159.102402 147.098480,160.365875 
	C146.981857,161.473831 146.933441,162.215912 146.563660,163.168762 
	C142.241486,182.473679 133.233810,199.057327 120.586113,214.092834 
	C119.823555,215.134506 119.354286,216.046249 118.600510,217.078796 
	C117.505806,218.118958 116.695610,219.038300 115.588341,220.090485 
	C113.513351,222.110718 111.735435,223.998138 109.653084,226.024673 
	C107.867477,227.422424 106.386314,228.681076 104.936920,229.954269 
	C104.968697,229.968811 104.939606,229.905243 104.635773,230.041885 
	C102.856346,231.432251 101.380745,232.685974 99.932663,233.953506 
	C99.960182,233.967316 99.933250,233.911880 99.617065,234.058197 
	C87.594536,240.444214 76.180504,247.345306 64.080048,252.693329 
	C55.886215,256.314697 46.727222,257.752350 37.999908,260.166656 
	C37.248028,260.121765 36.496151,260.076874 35.020039,260.040192 
	C33.197189,260.421143 32.098576,260.793915 30.999964,261.166687 
	C29.931118,261.121521 28.862270,261.076355 27.044598,261.039795 
	C25.197170,261.421173 24.098570,261.793915 22.999968,262.166656 
z" />
  <path fill="transparent" opacity="1.000000" stroke="#ffffff" d="
M242.042145,116.885162 
	C226.137772,97.540726 210.285995,78.152740 194.310471,58.867241 
	C183.750015,46.118763 173.007034,33.521545 162.359863,20.844755 
	C161.103378,19.348743 159.936310,17.777601 158.375336,15.793760 
	C158.030716,14.934163 158.038589,14.522090 158.046448,14.110017 
	C214.039520,14.080099 270.032593,14.050182 326.507019,14.434734 
	C324.285370,18.887091 321.582397,22.924976 318.586151,27.092804 
	C317.823608,28.134476 317.354340,29.046206 316.600586,30.078751 
	C315.505859,31.118916 314.695648,32.038265 313.591583,33.087921 
	C312.826843,34.131466 312.355957,35.044701 311.581055,36.097252 
	C309.145508,38.811127 307.014008,41.385677 304.588745,44.090508 
	C303.824158,45.133926 303.353333,46.047062 302.598206,47.080841 
	C301.504395,48.120193 300.694916,49.038902 299.580322,50.139935 
	C296.837311,53.841885 294.399353,57.361504 291.617584,60.940590 
	C289.810028,62.653454 288.346252,64.306854 286.588898,66.090363 
	C285.825226,67.132965 285.355164,68.045456 284.581024,69.097267 
	C282.145477,71.811142 280.013977,74.385696 277.588745,77.090530 
	C276.824158,78.133949 276.353333,79.047089 275.598206,80.080872 
	C274.504425,81.120216 273.694946,82.038918 272.591614,83.087921 
	C271.826843,84.131454 271.355957,85.044693 270.581024,86.097267 
	C268.145477,88.811142 266.013977,91.385689 263.588867,94.090347 
	C262.825226,95.132950 262.355133,96.045441 261.580994,97.097260 
	C259.145477,99.811134 257.013977,102.385689 254.588898,105.090347 
	C253.825226,106.132957 253.355148,107.045441 252.581024,108.097260 
	C250.145615,110.811020 248.014221,113.385460 245.656982,116.032173 
	C245.145386,116.290504 244.985489,116.553154 244.588913,116.935867 
	C243.498337,116.947945 242.770233,116.916550 242.042145,116.885162 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M38.107269,260.436127 
	C46.727222,257.752350 55.886215,256.314697 64.080048,252.693329 
	C76.180504,247.345306 87.594536,240.444214 99.629044,234.491699 
	C99.985527,236.224213 100.013840,237.669510 100.042152,239.114822 
	C87.889816,249.097015 73.644417,254.964310 58.970303,259.979462 
	C57.736073,260.401306 56.440220,260.642853 54.491486,260.648315 
	C49.996494,260.441284 46.183010,260.554016 42.369522,260.666748 
	C42.380585,261.261444 42.391647,261.856140 42.402710,262.450836 
	C43.306557,262.648102 44.210403,262.845398 45.114250,263.042664 
	C44.801098,263.481995 44.487942,263.921356 44.112560,264.627502 
	C38.022934,269.185333 40.050529,262.254181 38.107269,260.436127 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M89.955582,38.112267 
	C89.362183,37.945606 88.768784,37.778938 88.175171,37.064438 
	C87.382835,31.958403 84.486954,31.861517 80.955582,33.112270 
	C79.711678,32.625072 78.467781,32.137878 77.192184,31.113491 
	C76.974373,29.935835 76.788246,29.295370 76.602119,28.654907 
	C75.720276,29.141069 74.838425,29.627234 73.956581,30.113400 
	C72.711258,29.624790 71.465942,29.136181 70.056068,28.347691 
	C68.918350,26.753025 68.198074,24.720114 66.930122,24.285681 
	C59.785782,21.837849 52.499458,19.807352 45.286423,17.554893 
	C43.613415,17.032452 42.037292,16.199743 40.235497,15.204421 
	C49.490768,17.317043 59.297096,18.810867 68.264381,22.400635 
	C78.172905,26.367191 87.312668,32.254108 96.558502,37.590042 
	C94.205025,37.954388 92.080299,38.033329 89.955582,38.112267 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M318.879425,26.962864 
	C321.582397,22.924976 324.285370,18.887091 326.970886,14.479515 
	C329.342682,13.130221 329.847656,13.959905 329.321381,16.310871 
	C329.079010,17.393604 329.501343,18.625134 329.864197,19.919586 
	C329.288849,21.308840 328.471588,22.567699 327.188538,23.753426 
	C325.986206,23.343102 325.249695,23.005909 324.513153,22.668716 
	C324.368317,22.908205 324.223480,23.147692 324.078644,23.387180 
	C324.756683,23.939447 325.434723,24.491714 326.112762,25.043978 
	C325.292053,26.305450 324.471344,27.566927 323.125732,28.704525 
	C321.360352,28.041388 320.119904,27.502127 318.879425,26.962864 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M39.709152,12.924650 
	C40.037544,13.517958 40.028545,13.926025 40.058334,14.637937 
	C37.654850,14.941779 35.212570,14.941779 32.770290,14.941779 
	C32.806271,14.093932 32.842255,13.246085 32.878235,12.398239 
	C35.042740,12.511963 37.207249,12.625687 39.709152,12.924650 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M242.092194,117.229187 
	C242.770233,116.916550 243.498337,116.947945 244.767822,117.204010 
	C246.244751,118.299568 247.180252,119.170448 248.115753,120.041336 
	C247.625488,120.972160 247.135223,121.902977 246.345993,122.971573 
	C244.745438,121.263962 243.443848,119.418587 242.092194,117.229187 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M23.151970,262.403442 
	C24.098570,261.793915 25.197170,261.421173 26.648001,261.107544 
	C25.768141,261.657837 24.536055,262.149017 23.151970,262.403442 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M31.151960,261.403442 
	C32.098576,260.793915 33.197189,260.421143 34.648037,260.107544 
	C33.768166,260.657837 32.536064,261.149017 31.151960,261.403442 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M157.851196,14.032324 
	C158.038589,14.522090 158.030716,14.934163 158.068909,15.652100 
	C157.961960,15.290185 157.808945,14.622408 157.851196,14.032324 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M12.100870,262.348114 
	C12.445833,262.123260 12.891713,262.079865 13.668839,262.101562 
	C13.400652,262.287628 12.801220,262.408600 12.100870,262.348114 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M90.101662,38.422211 
	C92.080299,38.033329 94.205025,37.954388 96.708420,37.903854 
	C97.087105,37.932259 97.030815,37.955566 97.192596,38.214317 
	C98.267899,38.961987 99.181419,39.450905 100.094948,39.939823 
	C100.094948,39.939823 100.032753,39.961700 100.195404,40.218399 
	C101.276848,40.969234 102.195648,41.463371 103.114441,41.957504 
	C124.277443,56.939877 138.577713,77.208679 147.473633,101.226654 
	C151.762360,112.805725 154.152466,124.999123 153.242859,137.579254 
	C152.961487,141.470657 153.060242,145.389557 152.513641,149.646393 
	C150.987610,149.985916 149.932892,149.974930 148.878189,149.963943 
	C148.934036,144.205002 148.989883,138.446060 149.287842,132.242752 
	C149.408859,131.198883 149.287766,130.599396 149.166656,129.999893 
	C148.788239,128.583954 148.409821,127.168015 148.280685,125.275230 
	C148.408859,124.198891 148.287766,123.599396 148.166656,122.999901 
	C147.788834,121.903809 147.410995,120.807716 147.334503,119.176880 
	C146.146118,113.428055 144.656387,108.213982 143.166656,102.999901 
	C142.776077,102.234299 142.385498,101.468697 142.040848,100.183685 
	C141.780060,99.109474 141.473358,98.554688 141.166656,97.999901 
	C140.776077,97.234299 140.385498,96.468697 140.040848,95.183685 
	C139.780075,94.109474 139.473358,93.554688 139.166656,92.999901 
	C131.573151,79.200844 123.368912,65.765549 111.509056,55.182285 
	C104.839676,49.230797 97.361526,44.185631 90.101662,38.422211 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M120.879402,213.962891 
	C133.233810,199.057327 142.241486,182.473679 147.065857,163.207092 
	C148.891403,163.137436 149.893387,163.240250 150.895370,163.343063 
	C150.918762,163.769638 150.942154,164.196228 150.612457,164.949951 
	C149.785217,165.560333 149.311050,165.843552 148.836884,166.126770 
	C149.502350,166.327866 150.167847,166.528992 150.833313,166.730118 
	C150.296585,168.243256 149.759857,169.756409 148.734436,171.672165 
	C144.916840,172.926437 146.624893,174.348846 147.833313,175.714645 
	C144.609879,187.234085 139.266769,197.706268 132.172318,207.302994 
	C130.050522,210.173141 127.840004,212.977707 125.135437,215.696564 
	C123.360291,215.041504 122.119850,214.502197 120.879402,213.962891 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M100.392197,239.058578 
	C100.013840,237.669510 99.985527,236.224213 99.945236,234.345398 
	C99.933250,233.911880 99.960182,233.967316 100.328049,233.953033 
	C103.508369,234.064987 105.018005,232.820511 104.939606,229.905243 
	C104.939606,229.905243 104.968697,229.968811 105.332977,229.953644 
	C108.525917,230.068130 110.016220,228.790970 109.957520,225.885559 
	C111.735435,223.998138 113.513351,222.110718 115.946556,220.087646 
	C118.439056,219.982437 120.276276,220.012894 122.113495,220.043335 
	C114.989746,226.363007 107.865997,232.682678 100.392197,239.058578 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M291.961395,60.881123 
	C294.399353,57.361504 296.837311,53.841885 299.945679,50.134373 
	C302.426666,50.000534 304.237152,50.054585 306.047668,50.108635 
	C302.270721,54.662170 298.493774,59.215710 294.168945,63.782570 
	C293.067841,62.824303 292.514618,61.852711 291.961395,60.881123 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M245.882843,115.959900 
	C248.014221,113.385460 250.145615,110.811020 252.944382,108.140053 
	C254.779739,108.376144 255.947723,108.708763 257.115692,109.041382 
	C256.295715,110.302704 255.475769,111.564034 254.189285,112.752808 
	C252.986206,112.343094 252.249664,112.005920 251.513107,111.668747 
	C251.368286,111.908234 251.223465,112.147720 251.078644,112.387207 
	C251.756683,112.939461 252.434738,113.491714 253.112778,114.043968 
	C252.293655,115.304558 251.474533,116.565147 250.128311,117.703262 
	C248.361740,117.040482 247.122299,116.500191 245.882843,115.959900 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M254.882462,104.960236 
	C257.013977,102.385689 259.145477,99.811134 261.944397,97.140060 
	C263.779755,97.376152 264.947723,97.708771 266.115692,98.041382 
	C265.292969,99.305145 264.470245,100.568909 263.223572,101.730820 
	C262.098999,101.253586 261.398346,100.878197 260.697723,100.502800 
	C260.228943,101.036469 259.760132,101.570137 259.291351,102.103798 
	C260.209778,102.439117 261.128235,102.774437 262.046631,103.109756 
	C261.913727,103.671295 261.780823,104.232841 260.936188,104.911064 
	C258.443817,105.005241 256.663147,104.982742 254.882462,104.960236 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M304.882477,43.960228 
	C307.014008,41.385677 309.145508,38.811127 311.944244,36.140053 
	C313.778656,36.376945 314.945831,36.710354 316.113007,37.043762 
	C315.291107,38.306801 314.469208,39.569843 313.223511,40.730927 
	C312.099030,40.253571 311.398407,39.878170 310.697754,39.502773 
	C310.228912,40.036476 309.760101,40.570179 309.291260,41.103886 
	C310.209717,41.439182 311.128174,41.774479 312.046631,42.109764 
	C311.621582,42.981407 311.196533,43.853058 309.973053,44.677948 
	C307.743927,44.407532 306.313202,44.183880 304.882477,43.960228 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M263.882477,93.960236 
	C266.013977,91.385689 268.145477,88.811142 270.944214,86.140068 
	C272.778656,86.376945 273.945831,86.710350 275.112976,87.043747 
	C274.945587,87.637825 274.778198,88.231895 274.062836,88.840134 
	C270.696594,88.811150 267.401337,88.437561 270.833282,92.724792 
	C270.106049,93.759499 269.378815,94.794205 268.126343,95.704834 
	C266.361572,95.040588 265.122040,94.500412 263.882477,93.960236 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M277.882477,76.960251 
	C280.013977,74.385696 282.145477,71.811142 284.944397,69.140060 
	C286.779755,69.376144 287.947723,69.708763 289.115662,70.041382 
	C288.947510,70.636047 288.779358,71.230721 288.067780,71.840652 
	C286.141083,72.273308 284.757812,72.690697 283.374542,73.108078 
	C283.954041,73.753296 284.533508,74.398521 285.113007,75.043747 
	C284.288696,76.308586 283.464386,77.573425 282.156250,78.679688 
	C280.409088,78.000816 279.145782,77.480530 277.882477,76.960251 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M45.512691,263.025024 
	C44.210403,262.845398 43.306557,262.648102 42.402710,262.450836 
	C42.391647,261.856140 42.380585,261.261444 42.369522,260.666748 
	C46.183010,260.554016 49.996494,260.441284 54.113800,260.595886 
	C51.582123,261.577942 48.746628,262.292664 45.512691,263.025024 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M275.401367,86.919281 
	C273.945831,86.710350 272.778656,86.376945 271.248291,86.000740 
	C271.355957,85.044693 271.826843,84.131454 272.956970,83.082352 
	C275.426666,83.000542 277.237183,83.054588 279.047668,83.108635 
	C277.928345,84.337357 276.809021,85.566086 275.401367,86.919281 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M147.878189,156.963943 
	C147.929077,155.904221 147.979965,154.844513 148.429626,153.309326 
	C150.163376,152.556030 151.498367,152.278198 152.833344,152.000366 
	C152.808212,153.540131 152.783081,155.079910 152.012802,156.822449 
	C150.137833,157.004807 149.008011,156.984375 147.878189,156.963943 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M254.588898,105.090347 
	C256.663147,104.982742 258.443817,105.005241 260.669525,105.035049 
	C259.975830,106.290474 258.837128,107.538582 257.407043,108.914040 
	C255.947723,108.708763 254.779739,108.376144 253.248413,108.000732 
	C253.355148,107.045441 253.825226,106.132957 254.588898,105.090347 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M291.617584,60.940590 
	C292.514618,61.852711 293.067841,62.824303 293.863220,63.923248 
	C293.283325,65.313400 292.461304,66.576195 291.155823,67.680046 
	C289.409088,67.000816 288.145782,66.480530 286.882477,65.960251 
	C288.346252,64.306854 289.810028,62.653454 291.617584,60.940590 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M316.397583,36.922935 
	C314.945831,36.710354 313.778656,36.376945 312.248291,36.000732 
	C312.355957,35.044701 312.826843,34.131466 313.976227,33.109558 
	C316.140808,33.348507 317.626923,33.696133 319.113007,34.043770 
	C318.302734,34.963219 317.492432,35.882660 316.397583,36.922935 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M286.588898,66.090363 
	C288.145782,66.480530 289.409088,67.000816 290.856262,67.819138 
	C290.574036,68.691162 290.107971,69.265152 289.378784,69.940262 
	C287.947723,69.708763 286.779755,69.376144 285.248413,69.000732 
	C285.355164,68.045456 285.825226,67.132965 286.588898,66.090363 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M319.406860,33.913475 
	C317.626923,33.696133 316.140808,33.348507 314.270081,32.979248 
	C314.695648,32.038265 315.505859,31.118916 316.963776,30.121548 
	C318.778656,30.376951 319.945801,30.710371 321.112976,31.043793 
	C320.642242,31.956926 320.171509,32.870056 319.406860,33.913475 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M277.588745,77.090530 
	C279.145782,77.480530 280.409088,78.000816 281.856262,78.819138 
	C281.571472,79.693352 281.102814,80.269531 280.031372,80.811279 
	C278.246552,80.504639 277.064514,80.232430 275.882477,79.960220 
	C276.353333,79.047089 276.824158,78.133949 277.588745,77.090530 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M304.588745,44.090508 
	C306.313202,44.183880 307.743927,44.407532 309.693359,44.799469 
	C309.372498,45.908783 308.532928,46.849815 307.060974,47.783844 
	C305.246582,47.504627 304.064545,47.232414 302.882507,46.960197 
	C303.353333,46.047062 303.824158,45.133926 304.588745,44.090508 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M151.178619,163.152802 
	C149.893387,163.240250 148.891403,163.137436 147.387222,162.996307 
	C146.933441,162.215912 146.981857,161.473831 147.429352,160.282806 
	C149.163376,159.556030 150.498367,159.278198 151.833344,159.000366 
	C151.709518,160.321091 151.585709,161.641815 151.178619,163.152802 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M263.588867,94.090347 
	C265.122040,94.500412 266.361572,95.040588 267.824768,95.844284 
	C267.580231,96.684334 267.112000,97.260872 266.379730,97.939400 
	C264.947723,97.708771 263.779755,97.376152 262.248413,97.000732 
	C262.355133,96.045441 262.825226,95.132950 263.588867,94.090347 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M321.377319,30.941505 
	C319.945801,30.710371 318.778656,30.376951 317.248291,30.000732 
	C317.354340,29.046206 317.823608,28.134476 318.586151,27.092806 
	C320.119904,27.502127 321.360352,28.041388 322.824646,28.844215 
	C322.579498,29.684929 322.110565,30.262074 321.377319,30.941505 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M122.409241,219.876007 
	C120.276276,220.012894 118.439056,219.982437 116.243629,219.954803 
	C116.695610,219.038300 117.505806,218.118958 119.023430,217.087433 
	C120.858658,216.997711 121.986465,217.020187 123.114265,217.042648 
	C122.977837,217.931320 122.841415,218.820007 122.409241,219.876007 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M123.407944,217.019150 
	C121.986465,217.020187 120.858658,216.997711 119.307938,216.966614 
	C119.354286,216.046249 119.823555,215.134506 120.586113,214.092834 
	C122.119850,214.502197 123.360291,215.041504 124.824493,215.844391 
	C124.599380,216.403854 124.150497,216.699753 123.407944,217.019150 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M275.598206,80.080872 
	C277.064514,80.232430 278.246552,80.504639 279.767334,80.913422 
	C279.940125,81.642929 279.774200,82.235863 279.327972,82.968719 
	C277.237183,83.054588 275.426666,83.000542 273.250824,82.952057 
	C273.694946,82.038918 274.504425,81.120216 275.598206,80.080872 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M302.598206,47.080841 
	C304.064545,47.232414 305.246582,47.504627 306.767334,47.913414 
	C306.940155,48.642918 306.774200,49.235851 306.327972,49.968712 
	C304.237152,50.054585 302.426666,50.000534 300.250793,49.952045 
	C300.694916,49.038902 301.504395,48.120193 302.598206,47.080841 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M245.656982,116.032173 
	C247.122299,116.500191 248.361740,117.040482 249.824829,117.844276 
	C249.580261,118.684303 249.112076,119.260818 248.379822,119.939339 
	C247.180252,119.170448 246.244751,118.299568 245.130310,117.160545 
	C244.985489,116.553154 245.145386,116.290504 245.656982,116.032173 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M271.021729,92.488152 
	C267.401337,88.437561 270.696594,88.811150 273.782166,88.980469 
	C273.103027,90.154922 272.156586,91.203217 271.021729,92.488152 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M285.393005,74.927193 
	C284.533508,74.398521 283.954041,73.753296 283.374542,73.108078 
	C284.757812,72.690697 286.141083,72.273308 287.786926,71.981277 
	C287.257294,73.007965 286.465149,73.909302 285.393005,74.927193 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M152.898438,151.669037 
	C151.498367,152.278198 150.163376,152.556030 148.497528,152.916916 
	C148.205643,152.102402 148.244629,151.204819 148.580902,150.135590 
	C149.932892,149.974930 150.987610,149.985916 152.437836,149.998260 
	C152.876740,150.445648 152.920135,150.891678 152.898438,151.669037 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M151.903061,158.677460 
	C150.498367,159.278198 149.163376,159.556030 147.497528,159.916901 
	C147.205643,159.102402 147.244629,158.204819 147.580902,157.135590 
	C149.008011,156.984375 150.137833,157.004807 151.691284,157.033630 
	C152.067551,157.479538 152.020172,157.917053 151.903061,158.677460 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M148.048676,175.448807 
	C146.624893,174.348846 144.916840,172.926437 148.539551,172.037186 
	C148.643570,173.060730 148.453796,174.121841 148.048676,175.448807 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M312.281067,42.022453 
	C311.128174,41.774479 310.209717,41.439182 309.291260,41.103886 
	C309.760101,40.570179 310.228912,40.036476 310.697754,39.502773 
	C311.398407,39.878170 312.099030,40.253571 312.920013,40.872929 
	C312.865417,41.389637 312.690460,41.662388 312.281067,42.022453 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M253.339325,113.970871 
	C252.434738,113.491714 251.756683,112.939461 251.078644,112.387207 
	C251.223465,112.147720 251.368286,111.908234 251.513107,111.668747 
	C252.249664,112.005920 252.986206,112.343094 253.885681,112.893913 
	C254.013931,113.448074 253.853012,113.711479 253.339325,113.970871 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M326.339294,24.970890 
	C325.434723,24.491714 324.756683,23.939447 324.078644,23.387180 
	C324.223480,23.147692 324.368317,22.908205 324.513153,22.668716 
	C325.249695,23.005909 325.986206,23.343102 326.885681,23.893942 
	C327.013947,24.448109 326.853027,24.711512 326.339294,24.970890 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M262.281067,103.022430 
	C261.128235,102.774437 260.209778,102.439117 259.291351,102.103798 
	C259.760132,101.570137 260.228943,101.036469 260.697723,100.502800 
	C261.398346,100.878197 262.098999,101.253586 262.919983,101.872925 
	C262.865387,102.389618 262.690430,102.662369 262.281067,103.022430 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M150.898346,166.486511 
	C150.167847,166.528992 149.502350,166.327866 148.836884,166.126770 
	C149.311050,165.843552 149.785217,165.560333 150.546356,165.264282 
	C150.876678,165.581940 150.920029,165.912415 150.898346,166.486511 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M102.976807,41.659264 
	C102.195648,41.463371 101.276848,40.969234 100.206512,40.187363 
	C100.983040,40.386757 101.911102,40.873890 102.976807,41.659264 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M99.960068,39.642609 
	C99.181419,39.450905 98.267899,38.961987 97.204697,38.186371 
	C97.978409,38.381580 98.901802,38.863487 99.960068,39.642609 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M143.112549,103.390694 
	C144.656387,108.213982 146.146118,113.428055 147.401245,118.820923 
	C145.797256,113.926971 144.427856,108.854225 143.112549,103.390694 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M81.101929,33.422440 
	C84.486954,31.861517 87.382835,31.958403 88.035278,36.784058 
	C85.679817,35.945206 83.464043,34.838909 81.101929,33.422440 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M109.653084,226.024673 
	C110.016220,228.790970 108.525917,230.068130 105.301208,229.939117 
	C106.386314,228.681076 107.867477,227.422424 109.653084,226.024673 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M104.635773,230.041885 
	C105.018005,232.820511 103.508369,234.064987 100.300522,233.939240 
	C101.380745,232.685974 102.856346,231.432251 104.635773,230.041885 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M74.117767,30.405209 
	C74.838425,29.627234 75.720276,29.141069 76.602119,28.654907 
	C76.788246,29.295370 76.974373,29.935835 77.028061,30.813919 
	C76.023399,30.933365 75.151176,30.815193 74.117767,30.405209 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M149.101562,130.331085 
	C149.287766,130.599396 149.408859,131.198883 149.348297,131.899033 
	C149.123260,131.553879 149.079880,131.108078 149.101562,130.331085 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M148.101562,123.331085 
	C148.287766,123.599396 148.408859,124.198891 148.348297,124.899033 
	C148.123260,124.553879 148.079865,124.108078 148.101562,123.331085 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M141.104568,98.301971 
	C141.473358,98.554688 141.780060,99.109474 141.989975,99.856781 
	C141.609619,99.567543 141.326050,99.085793 141.104568,98.301971 
z" />
  <path stroke-width="16" opacity="1.000000" stroke="#ffffff" d="
M139.104568,93.301971 
	C139.473358,93.554688 139.780075,94.109474 139.989975,94.856781 
	C139.609604,94.567551 139.326050,94.085793 139.104568,93.301971 
z" />
</svg>