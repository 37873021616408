import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { stompBaseConfig } from 'core/config';
import { APP_CONFIG, ErrorHandlingService, SERVER_STATE_CHECK_DELAY } from 'core/services';
import { Subscription } from 'rxjs';
import { rootActions } from 'core/root-store';
import { first } from 'rxjs/operators';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root',
})
export class HealthStompService extends RxStomp implements OnDestroy {
  subscriptions: Subscription[] = [];
  rootUri: string;
  environment: string;
  connectionCheckInterval: any = null;

  constructor(injector: Injector, private actions$: Actions, private errorHandlingService: ErrorHandlingService) {
    super();
    this.rootUri = injector.get(APP_CONFIG).rootUri.replace('https:', 'wss:').replace('http:', 'ws:');
    this.environment = injector.get(APP_CONFIG).environment;
    this.initSocketConnection();
    this.actions$.pipe(
      ofType(rootActions.VALIDATION_SUCCESS), first()).subscribe(data => {
        if (this.connected()) {
          clearInterval(this.connectionCheckInterval);
          this.errorHandlingService.resetErrorState();
          this.deactivate();
        }
      })
  }

  initSocketConnection() {
    this.configure(stompBaseConfig(`${this.rootUri}health`, undefined, this.environment));
    this.activate();
    this.checkForConnectionState();
  }

  checkForConnectionState() {
    if (this.connectionCheckInterval) clearInterval(this.connectionCheckInterval);
    this.connectionCheckInterval = setInterval(() => this.errorHandlingService.setServerDownState(!this.connected()),
      SERVER_STATE_CHECK_DELAY);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
