import { Injectable } from '@angular/core';
import { AuthStompService } from '../auth-stomp-service/auth-stomp.service';
import { Store, select } from '@ngrx/store';
import { rootReducers, rootSelectors } from 'core/root-store';
import { AuthenticatedUser, ISocketMessage, eventType } from 'core/models';
import { first, map } from 'rxjs/operators';
import { DBTableNames, IndexedDBService, TABLE_VERSION_INCREMENT_NUMBER } from 'core/services';
import { ITableSyncStatus } from 'core/config';
import { rootActions } from 'core/root-store';
import { Actions, ofType } from '@ngrx/effects';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable()
export class TableSyncStompService extends RxStomp {
  private handlers: Partial<Record<eventType, (ISocketMessage: ISocketMessage) => void>> = {
    update: this.updateHandler,
  };
  userDetail: AuthenticatedUser;
  isFetchingNameReference: boolean = false;

  constructor(private authStompService: AuthStompService,
    private modelerStore: Store<rootReducers.authenticationReducer.IAuthenticationState>,
    private indexedDB: IndexedDBService,
    private actions$: Actions) {
    super();
    this.subscribeAuthenticatedUser();
  }

  subscribeAuthenticatedUser(): void {
    this.modelerStore.pipe(select(rootSelectors.getAuthenticatedUser)).subscribe((user: AuthenticatedUser) => {
      if (this.userDetail?.userId == user.userId) return;
      this.userDetail = user;
      this.subscribeTableSyncEvent(user.accountId);
    })
  }

  subscribeTableSyncEvent(accountId: string): void {
    const stompSubscription = this.authStompService.watch(`/tableSync/${accountId}`);
    stompSubscription
      .pipe(map((message: any) => JSON.parse(message.body)))
      .subscribe((message: ISocketMessage) => {
        if (!this.userDetail?.accountId) return;
        this.handlers[message.eventType].call(this, message);
      });
  }

  async updateHandler(socketMessage: ISocketMessage) {
    if (this.isFetchingNameReference == false) {
      const tableSyncState: ITableSyncStatus = socketMessage.data.tableStates[0];
      tableSyncState.version = Math.round(tableSyncState.version * 10) / 10;
      const syncTableEntryCount = await this.indexedDB.getTableEntryCount(DBTableNames.TableSyncStatus);
      let result = null;
      if (syncTableEntryCount == 1) {
        result = await this.indexedDB.getTableVersion(this.userDetail.accountId, tableSyncState.tableName);
      } else if (syncTableEntryCount > 1) {
        await this.indexedDB.clearTableEntries(DBTableNames.TableSyncStatus);
      }
      if (!result || result.version != tableSyncState.version) {
        await this.indexedDB.setTableVersion(tableSyncState);
      }
      if (!result || this.isNameReferenceOutOfSync(result.version, tableSyncState.version)) {
        this.isFetchingNameReference = true;
        this.modelerStore.dispatch(new rootActions.LoadNameReferenceTable());
        this.actions$.pipe(ofType(rootActions.LOAD_NAMEREFERENCE_TABLE_SUCCESS, rootActions.LOAD_NAMEREFERENCE_TABLE_FAILED), first())
          .subscribe(async ({ payload }: any) => {
            if (payload.response) {
              await this.indexedDB.setToNameReferenceTable(payload.response, true);
              this.isFetchingNameReference = false;
            } else {
              this.isFetchingNameReference = false;
            }
          });
      }
    }
  }

  isNameReferenceOutOfSync(uiTableVersion: number, backendTableVersion: number): boolean {
    return (backendTableVersion - uiTableVersion) > TABLE_VERSION_INCREMENT_NUMBER;
  }
}
