import { EnvName, IdentityManagement } from './config.model';
import { IConfigMap } from './configMap';

export const tempDevConfig: IConfigMap = {
    environment: EnvName.TEMPDEV,
    name: EnvName.TEMPDEV,
    proxyUri: '',
    rootUri: 'https://temp-dev.api.decisionsfirst.com/',
    authBaseUrl: 'https://temp-dev.api.decisionsfirst.com/ims/',
    identityManagement: IdentityManagement.INAPP,
    wijmoLicenseKey: 'temp-dev.modeler2.decisionsfirst.com,423218326587541#B0YIJVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdycVV0hmZoNlM6gHc4QGSQJFUFJXd6dVTvQTQzlGckNFcwR6dGVFd9Y5ajZ4VYJFOzE6NHZjUTNWcVNFexJzMzxUWTZTSxIlZzMTNVl7SBFVZtBDS9A7SGRFbUFVd5EUZGhXSHx4cENnWmF4Ksdmak3iREdVYmRUN6sEaYNUSQdXN6RVOI3kRopnejVDa5V7QYpHW4VjdzM5TRRmS5U7M6N5UhRWdiZ4UkxUT8smMWF6Z9AFbmZ4ZOV7ayVlTlhXY7pncQ3Wd7pWSvdzKQhzRXBDUyF4Mk56RGhTN4Q6LsNjNRhDMDZVNwhzYq3mdTR6dz94ajBla6RUVDxUT6wkbCVDTChTM54GOrpWYkNTYZl4SDVGUhtyMMZXRNVkNvlnQrd7ZWB7SlRDWHh7RxclcXVVWEhERaBTQElkThJ5cv24VMhXSRpnVSpFNEVGZJRXa054SphmI0IyUiwiIwcDM6YkR5QjI0ICSiwCO9ADM9ADN7UTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiEDMzUTMxASOwQDM4IDMyIiOiQncDJCLi46bj9CdzJXamNnbvl6cpNWZk9iMyVGblR6bt9idlRWLw5WZ4JiOiMXbEJCLiMnbvlGd5x6bTBCduVWbldWYuFWTg86bpNXajVGRiojIh94QiwiIxQTN7gTN6IzM8EjMzIDNiojIklkIs4nIzYHMyAIMJo',
    goJsLicenseKey: '73f942e1b46128a800ca0d2b113f69ee1bb37b679ed11ef45e0042f5ef5f68112bc9ec7958868fc7d5f84ffa1c28c6db8ecd6d2fc01b172be06383dc43b0d6fee03571b1145c4e88a1532eca9dfd2cf3fc2876f7c0e724a7de70dbf6e8a0c4cb54b8a6874c995db82d7a0764527efc4ae4ffdd7cfb04c8486938caf4a9eeac4ce87c71d1cee257dabf5863cebceca35d697208d6',
    internetConnectivityTestUrl: 'https://tempDev.images.decisionsfirst.com/images/imagesdfm.jpg'
};