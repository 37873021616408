import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Resource } from "@lagoshny/ngx-hateoas-client";
import { IResultsWithPages } from "core/models";
import { getPagination } from "core/utilities";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ExternalConfigService } from "../external-config-service/external-config.service";

export class CommonService<T extends Resource> {
  protected _resource: string;
  protected toObject: any;
  protected pageSize: number;

  constructor(
    type: new () => T,
    resourcePath: string,
    protected externalService: ExternalConfigService,
  ) {
    this._resource = resourcePath;
  }

  createObject(tagId: string, parentTagId: string, object: T, relationObjectKey?: any, relatedObject?: any) {
    if (relationObjectKey) {
      return this.externalService
        .getHttp()
        .post(
          `${this.externalService.getURL()}${this._resource
          }/${relationObjectKey}/object/${relatedObject.id}?tagId=${tagId}&parentTagId=${parentTagId}`,
          object
        );
    } else {
      return this.externalService
        .getHttp()
        .post(
          `${this.externalService.getURL()}${this._resource
          }?tagId=${tagId}&parentTagId=${parentTagId}`,
          object
        );
    }
  }

  getSingleMinimal(decisionId: string): Observable<T> {
    return this.externalService
      .getHttp()
      .get(
        `${this.externalService.getURL()}${this._resource
        }/${decisionId}?projection=minimal`
      )
      .pipe(map(this.toObject));
  }

  getSingleEdit(objectId: string): Observable<T> {
    return this.externalService
      .getHttp()
      .get(
        `${this.externalService.getURL()}${this._resource
        }/${objectId}?projection=edit`
      )
      .pipe(map(this.toObject));
  }

  getSingleObjectForDiagram(objectId: string): Observable<T> {
    return this.externalService
      .getHttp()
      .get(
        `${this.externalService.getURL()}${this._resource
        }/${objectId}?projection=diagram`
      )
      .pipe(map(this.toObject));
  }

  getSingleObject(href: string, objectId: string): Observable<T> {
    return this.externalService
      .getHttp()
      .get(
        `${href}/objects/${this._resource
        }/${objectId}`
      )
      .pipe(map(this.toObject));
  }

  // diagrams/{id}/objects/{type}/{objectId}

  getSomeMinimalWithSearch(
    searchTerm = "",
    pageNumber = 0
  ): Observable<IResultsWithPages<T>> {
    const uriEncodedSearchTerm = encodeURIComponent(searchTerm);
    const url =
      `${this.externalService.getURL()}${this._resource
      }/search/findByNameIgnoreCaseContainingOrDescriptionIgnoreCaseContaining` +
      `?partialName=${uriEncodedSearchTerm}&partialDescription=${uriEncodedSearchTerm}&size=${this.pageSize}&page=${pageNumber}&projection=minimal`;
    return this.externalService
      .getHttp()
      .get(url)
      .pipe(map((object: any) => this.toPageableObject(object)));
  }

  getByUrl(url: string): Observable<IResultsWithPages<T>> {
    return this.externalService
      .getHttp()
      .get(url)
      .pipe(map((object: any) => this.toPageableObject(object)));
  }

  addRelatedObject(
    sourceObjectRelatedHref: string,
    body: string,
    headers: HttpHeaders
  ): Observable<Object> {
    return this.externalService.getHttp().post(sourceObjectRelatedHref, body, {
      headers,
    });
  }

  removeRelatedObject(
    sourceObjectRelatedHref: string,
    relatedObjectId: string,
    headers?: HttpHeaders
  ): Observable<Object> {
    return this.externalService
      .getHttp()
      .delete(`${sourceObjectRelatedHref}/${relatedObjectId}`, {
        headers: headers,
      });
  }

  updateRelatedObject(
    sourceObjectRelatedHref: string,
    body: any,
    headers?: HttpHeaders
  ): Observable<Object> {
    return this.externalService.getHttp().patch(sourceObjectRelatedHref, body, {
      headers,
    });
  }

  toPageableObject(objs: any): IResultsWithPages<T> {
    const pagination = getPagination(objs);
    const results = [
      ...(objs._embedded ? objs._embedded[this._resource] || [] : []).map(
        this.toObject
      ),
    ];
    return {
      pagination,
      results,
    };
  }

  toPageableConfiguration(objs: any): IResultsWithPages<T> {
    const pagination = getPagination(objs);
    const results = [
      ...(objs._embedded ? objs._embedded['archiveConfigurations'] || [] : []).map(
        this.toObject
      ),
    ];
    return {
      pagination,
      results,
    };
  }

  toPageableHistories(objs: any): IResultsWithPages<T> {
    const pagination = getPagination(objs);
    const results = [
      ...(objs._embedded ? objs._embedded['archiveHistories'] || [] : []).map(
        this.toObject
      ),
    ];
    return {
      pagination,
      results,
    };
  }

  toPageableBridgeHistory(objs: any): IResultsWithPages<T> {
    const pagination = getPagination(objs);
    const results = [
      ...(objs._embedded ? objs._embedded['jobRunHistories'] || [] : [])];
    return {
      pagination,
      results,
    };
  }

  create(obj: any, headers?: HttpHeaders): Observable<Object> {
    const url = `${this.externalService.getURL()}${this._resource}`;
    return this.externalService.getHttp().post(url, obj, { headers })
      .pipe(map(res => this.toObject({ ...obj, ...(res || {}) })));
  }

  patch(obj: any, headers?: HttpHeaders): Observable<Object> {
    const url = obj._links.self.href;
    return this.externalService.getHttp().patch(url, obj, { headers })
      .pipe(map(res => this.toObject({ ...obj, ...(res || {}) })));
  }

  delete(obj: any): Observable<Object> {
    const url = obj._links.self.href;
    return this.externalService.getHttp().delete(url);
  }
}
