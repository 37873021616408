import { Injectable } from '@angular/core';
import { ISocketMessage } from 'core/models';
import { map } from 'rxjs/operators';

import { HealthStompService } from './health-stomp.service';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckStompService {

  constructor(
    private healthStompService: HealthStompService
  ) {
    this.subscribeHealthCheckEvent()
  }

  subscribeHealthCheckEvent(): void {
    const stompSubscription = this.healthStompService.watch(`/healths`);
    stompSubscription
      .pipe(map((message: any) => JSON.parse(message.body)))
      .subscribe((message: ISocketMessage) => {
        console.log(message)
        // this.handlers[message.eventType].call(this, message);
      });
  }
}
