import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormGroup, NgControl } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { rootActions } from 'core/root-store';

@Component({
  selector: 'core-action-trackable',
  template: ''
})
export class ActionTrackableComponent implements OnDestroy {
  private _editObjectId: string = null;
  @Input() set editObjectId(value: string) {
    if (value && !this._editObjectId) {
      this.subscribeUserActivityAction(value);
    }
    this._editObjectId = value;
  }
  get editObjectId(): string {
    return this._editObjectId;
  }
  activeUserOnField: string = null;
  ngControl: NgControl;
  fieldFormGroup: UntypedFormGroup;
  disableControls: boolean = false;
  private _isDisabledInitially: boolean = null;
  disableTracking: boolean = false;

  constructor(private actionsSubject$: ActionsSubject,
    public changeDetector: ChangeDetectorRef) { }

  subscribeUserActivityAction(editObjectId: string) {
    if (this.disableTracking) return;
    this.actionsSubject$.pipe(ofType(rootActions.UPDATED_FORM_FIELD_USER_ACTION_STATE)).pipe(untilDestroyed(this))
      .subscribe(({ payload }: any) => {
        if (this.disableTracking) return;
        if (payload[editObjectId]) {
          const field = payload[editObjectId].find(field => field.fieldName == this.ngControl?.name);
          if (field) {
            if (this._isDisabledInitially == null) this._isDisabledInitially = this.ngControl.control.disabled;
            this.ngControl.control.disable();
            this.activeUserOnField = `${field.user.firstName} ${field.user.lastName}`;
          } else {
            if (this._isDisabledInitially == null) this._isDisabledInitially = this.ngControl.control.disabled;
            this.activeUserOnField = null;
            if (!this._isDisabledInitially) this.ngControl.control.enable();
          }
          this.changeDetector.detectChanges();
        }
      });
    this.actionsSubject$.pipe(ofType(rootActions.ACTION_ON_DATA_TYPE_FIELD)).pipe(untilDestroyed(this))
      .subscribe(({ payload }: any) => {
        if (this.disableTracking) return;
        if (editObjectId == payload.dataType.objectId && this.fieldFormGroup) {
          if (payload.dataType.action == 'focused') {
            if (this._isDisabledInitially == null) this._isDisabledInitially = this.fieldFormGroup.disabled;
            this.fieldFormGroup.disable({ emitEvent: false });
            this.disableControls = true;
            this.activeUserOnField = `${payload.user.firstName} ${payload.user.lastName}`;
          } else {
            this.activeUserOnField = null;
            if (!this._isDisabledInitially) {
              this.fieldFormGroup.enable({ emitEvent: false });
              this.disableControls = false;
            }
          }
          this.changeDetector.detectChanges();
        }
      });
  }

  ngOnDestroy(): void { }
}
