import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as go from 'gojs';
import { Config } from './config';
import * as wjcCore from '@grapecity/wijmo';
import {ThemeNames} from "core/models";

@Component({
  selector: 'dfm-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private translateService: TranslateService) {
    this.setWijmoLicenseKey();
    this.setGoJsLicenseKey();
    this.setDefaultLanguage();
    this.setDefaultTheme();
  }

  ngOnInit(): void { }

  setWijmoLicenseKey() {
    wjcCore.setLicenseKey(Config.wijmoLicenseKey);
  }

  setGoJsLicenseKey(): void {
    (go as any).licenseKey = Config.goJsLicenseKey;
  }

  setDefaultLanguage(): void {
    const languageCode = localStorage.getItem('languageCode');
    this.translateService.setDefaultLang(languageCode ? languageCode : Config.defaultLanguageCode);
  }
  setDefaultTheme():void{
    localStorage.setItem("themeName",ThemeNames.DEFAULT)
  }
}
