import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ActionsSubject, Store } from '@ngrx/store';
import { AddObjectComponent, MaxTextLengthCategory } from 'core/components';
import { EMAIL_PATTERN } from 'core/constants';
import { UserType } from 'user-management/models';
import { IDecisionFirstState } from 'user-management/store/reducers';
import * as fromCustomerStore from 'user-management/store/actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'user-management-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent extends AddObjectComponent implements OnInit {
  MaxTextLengthCategory = MaxTextLengthCategory;
  @Input() addAction: any;
  @Input() customerId: string;
  types: Array<UserType> = [UserType.ADMIN, UserType.STANDARD, UserType.READER, UserType.DISABLED];
  @Input() set userTypesList(value: Array<UserType>) {
    this.types = value;
  }
  get userTypeList(): Array<UserType> {
    return this.types;
  }

  constructor(
    private userManagementStore: Store<IDecisionFirstState>,
    private nbDialogRef: NbDialogRef<AddUserComponent>,
    private actions$: ActionsSubject,
  ) {
    super(userManagementStore, nbDialogRef, actions$);

    this.formGroup = this.getUserForm();
    this.addAction = this.addAction;
    this.addActionSuccess = fromCustomerStore.ADD_USER_SUCCESS;
    this.addActionFailure = fromCustomerStore.ADD_USER_FAILURE;
  }

  ngOnInit() {
    this.setFormValue();
  }

  getUserForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      user: new UntypedFormGroup({
        firstName: new UntypedFormControl('', [Validators.required]),
        lastName: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
        type: new UntypedFormControl('', [Validators.required]),
      }),
      accountId: new UntypedFormControl('')
    });
  }

  setFormValue() {
    this.formGroup.patchValue({ user: { type: UserType.STANDARD }, accountId: this.customerId }, { emitEvent: false });
  }
}
