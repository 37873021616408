import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SERVER_STATE_CHECK_DELAY } from '../constants';
import { ExternalConfigService } from '../external-config-service/external-config.service';

interface IErrorState {
  isStatusZeroError: boolean;
  isServerDown: boolean;
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService implements OnDestroy {
  serverStateCheckTimeout$: Subject<IErrorState>;
  errorState$: Subject<IErrorState>;
  private _errorState: IErrorState = { isStatusZeroError: false, isServerDown: false };
  private _timeout: any = null;
  isOnline: boolean = true;
  _checkingInternet: boolean = false;

  constructor(private externalConfigService: ExternalConfigService) {
    this.serverStateCheckTimeout$ = new Subject<IErrorState>();
    this.errorState$ = new Subject<IErrorState>();
  }

  setStatusZeroError(value: boolean) {
    if (value === this._errorState.isStatusZeroError) return;
    if (value === true) {
      if (this.isOnline === false || this._errorState.isServerDown) return;
      this.stopTimer();
    }
    this._errorState.isStatusZeroError = value;
    this.errorState$.next(this._errorState);
    localStorage.setItem('errorState', JSON.stringify(this._errorState));
  }

  setServerDownState(value: boolean) {
    if (value === this._errorState.isServerDown) return;
    this._errorState.isServerDown = value;
    if (value === true) {
      localStorage.setItem('errorState', JSON.stringify(this._errorState));
      if (this.isOnline === false) return;
      else {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 3000);
        fetch(this.externalConfigService.getExternalConfiguration()['internetConnectivityTestUrl'], { signal: controller.signal }).then(res => {
          if (res.status === 200) {
            this.stopTimer();
            this.isOnline = true;
            this.errorState$.next(this._errorState);
          }
        }).catch(err => {
          this.isOnline = false;
          this.errorState$.next(this._errorState);
        });
      }
    } else {
      this.isOnline = true;
      this.errorState$.next(this._errorState);
      localStorage.setItem('errorState', JSON.stringify(this._errorState));
    }
  }

  getErrorState() {
    return JSON.parse(localStorage.getItem('errorState'));
  }

  resetErrorState() {
    this.stopTimer();
    this._errorState = { isStatusZeroError: false, isServerDown: false };
    this.errorState$.next(this._errorState);
    localStorage.setItem('errorState', JSON.stringify(this._errorState));
  }

  startTimer() {
    if (this._timeout !== null) return;
    this._timeout = false;
    if (this.isOnline === true) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 3000);
      fetch(this.externalConfigService.getExternalConfiguration()['internetConnectivityTestUrl'], { signal: controller.signal }).then(res => {
        if (res.status === 200) {
          this.createTimeout();
        }
      }).catch(err => {
        this._timeout = null;
        this.isOnline = false;
        this.errorState$.next(this._errorState);
      });
    } else {
      this.createTimeout();
    }
  }

  createTimeout() {
    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.serverStateCheckTimeout$.next(this._errorState);
    }, SERVER_STATE_CHECK_DELAY + 10);
  }

  stopTimer() {
    clearTimeout(this._timeout);
    this._timeout = null;
  }

  setOnlineState(value: boolean) {
    this.isOnline = value;
    this.errorState$.next(this._errorState);
  }

  ngOnDestroy(): void {
    this.serverStateCheckTimeout$?.complete();
    this.errorState$?.complete();
  }
}