import { Injectable, OnDestroy } from '@angular/core';
import { UserAutoFocusoutTime } from 'core/constants';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject, fromEvent, interval, merge } from 'rxjs';
import { repeat, takeUntil } from 'rxjs/operators';

@Injectable()
export class UserTrackingService implements OnDestroy {
  actionOnFormField$: Subject<{ eventType: 'create' | 'delete', action: 'focused' | 'focusout', fieldName: string, objectId: string }> = new Subject<{ eventType: 'create' | 'delete', action: 'focused' | 'focusout', fieldName: string, objectId: string }>();
  actionOnDataTypeFormField$: Subject<{ eventType: 'create' | 'delete', action: 'focused' | 'focusout', objectId: string }> = new Subject<{ eventType: 'create' | 'delete', action: 'focused' | 'focusout', objectId: string }>();
  autoFocusout$: Subject<void> = new Subject<void>();
  lastUserAction: any = null;
  intervalId: any = null;

  constructor() {
    const hiddenInput = document.querySelector('#hiddenInput') as HTMLInputElement;
    this._browserIdle$(UserAutoFocusoutTime).pipe(untilDestroyed(this)).subscribe(res => {
      if (document.activeElement instanceof HTMLSelectElement
        || document.activeElement instanceof HTMLInputElement
        || document.activeElement instanceof HTMLTextAreaElement
        || document.activeElement instanceof HTMLOptionElement
        || document.activeElement['contentEditable'] == 'true') {
        hiddenInput.focus();
        setTimeout(() => this.autoFocusout$.next(), 100);
      } else {
        this.autoFocusout$.next();
      }
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.lastUserAction = null;
      }
    });
  }

  onActFormField(action: 'focused' | 'focusout', fieldName: string, objectId: string) {
    if (!objectId) return;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    let eventType;
    if (action == 'focused') {
      eventType = 'create';
      this.lastUserAction = { eventType, action, fieldName, objectId };
      this.intervalId = setInterval(() => {
        this.actionOnFormField$.next(this.lastUserAction);
      }, 20000);
    } else {
      eventType = 'delete';
      this.lastUserAction = null;
    }
    this.actionOnFormField$.next({ eventType, action, fieldName, objectId });
  }

  onActDataTypeFormField(action: 'focused' | 'focusout', objectId: string) {
    if (!objectId) return;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    let eventType;
    if (action == 'focused') {
      eventType = 'create';
      this.lastUserAction = { eventType, action, objectId };
      this.intervalId = setInterval(() => {
        this.actionOnDataTypeFormField$.next(this.lastUserAction);
      }, 20000);
    } else {
      eventType = 'delete';
      this.lastUserAction = null;
    }
    this.actionOnDataTypeFormField$.next({ eventType, action, objectId });
  }

  private _browserIdle$ = (graceTime: number): Observable<any> => {
    const userActionEvents$ = merge(
      ...['click', 'mousedown', 'scroll', 'keypress', 'drag'].map(
        (eventName) => fromEvent(document, eventName)
      )
    );

    return interval(graceTime).pipe(
      takeUntil(userActionEvents$),
      repeat()
    );
  };

  ngOnDestroy(): void {
    this.actionOnFormField$?.complete();
    this.actionOnDataTypeFormField$?.complete();
    this.autoFocusout$?.complete();
  }
}
