<nb-layout>
  <nb-layout-header fixed class="loginHeader">
    <core-logo></core-logo>
    <span class="env-info" *ngIf="environmentName === 'dev'"> {{environmentName | uppercase}}</span>
  </nb-layout-header>

  <nb-layout-column>
    <nb-card>
      <div *ngIf="loginContainerState$ | async as state">
        <div class="tab-item row no-margin login-form-container" *ngIf="!state.wSO2Error"
          [hidden]="!isOnline || isServerDown || isStatusZeroError">
          <div class="mt-5 col-sm-10 offset-sm-1 col-md-4 col-lg-4">
            <div class="d-inline-flex">
              <object style="width: 6rem;" class="logo" data="assets/images/logo.svg" type="image/svg+xml">
                <img style="width: 6rem;" class="logo" src="assets/images/logo.png" />
              </object>
              <span class="h3 mb-0 primary-color">DFM</span>
            </div>
            <p class="mt-3">Welcome to DecisionsFirst Modeler, a collaborative decision modeling platform based on the
              Decision Model and Notation (DMN) standard.</p>
            <div class="login-version-information-container" *ngIf="displayVersionInformation">
              <div [innerHtml]="state.versionInformation"></div>
              <div>
                <a class="primary-color" target="_blank" href="{{ getUrlWithProtocol(state.supportLink) }}">{{
                  getUrlWithoutProtocol(state.supportLink) }}</a>
              </div>
            </div>
          </div>
          <div class="mt-5 col-sm-10 offset-sm-1 col-md-4 col-lg-3 offset-lg-2">
            <core-login *ngIf="!isForgotPasswordDisplayed" [authenticationState]="state"
              (sendLogin)="onSendLogin($event)" (forgotPassword)="onToggleForm()"
              [showForgotPasswordOption]="showForgotPasswordOption" [showRememberOption]="showRememberOption">
            </core-login>
            <core-forgot-password *ngIf="isForgotPasswordDisplayed" (sendForgotPassword)="onSendForgotPassword($event)"
              [inProgress]="state.forgotPasswordInProgress">
            </core-forgot-password>
            <div class="forms-links-container">
              <!-- <div *ngIf="!isForgotPasswordDisplayed && showForgotPasswordOption" (click)="onToggleForm()">
              {{ 'resources.forgotPassword' | translate }}</div> -->
              <div class="primary-color" *ngIf="isForgotPasswordDisplayed" (click)="onToggleForm()">{{ 'resources.login'
                |
                translate }}</div>
            </div>
          </div>
        </div>
        <div class="tab-item" *ngIf="state.wSO2Error || !isOnline || isServerDown || isStatusZeroError">
          <core-something-went-wrong-screen [attr.class]="'h-100'" [userMessages]="somethingWentWrongMessages"
            [description]="description" [showBackButton]="isStatusZeroError">
          </core-something-went-wrong-screen>
        </div>
      </div>
      <span class=" primary-color mr-auto ml-auto mt-2">{{'Brought to you by Decision Management Solutions'}}</span>
    </nb-card>
  </nb-layout-column>
</nb-layout>