import { RxStompConfig } from '@stomp/rx-stomp';

export const stompBaseConfig = (url: string, headers?: any, environment?: any): RxStompConfig => ({
  brokerURL: url,

  ...(headers && { connectHeaders: headers }),

  heartbeatIncoming: 0,
  heartbeatOutgoing: 20000,

  reconnectDelay: 1000,

  ...(environment == 'dev' && {
    debug: (msg: string): void => {
      // console.log(new Date(), msg);
    }
  })
});