import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ExternalConfigService {
  constructor(@Optional() @Inject('ConfigService') private config: any) { }

  getProxyUri(): string {
    return this.config.getProxyUri();
  }

  getRootUri(): string {
    return this.config.getRootUri();
  }

  getHttp(): HttpClient {
    return this.config.getHttp();
  }

  getIdentityManagement(): string {
    return this.config.getIdentityManagement();
  }

  getAuthBaseUrl(): string {
    return this.config.getAuthBaseUrl();
  }

  getExternalConfiguration() {
    return this.config.getExternalConfiguration();
  }

  getURL() {
    return this.config.getRootUri();
  }
}