import { Routes } from '@angular/router';
import { AuthenticatedGuardService, LoginGuardService } from 'core/auth';
import { CallbackComponent, LoggedOutComponent, LoginContainerComponent, NotFoundComponent, SessionTimeoutComponent, RequestFailedMessageComponent } from 'core/containers';

export const ROUTES: Routes = [
  {
    canActivate: [AuthenticatedGuardService],
    loadChildren: () => import('./decision-first/decisionFirst.module').then(m => m.DecisionFirstModule),
    path: 'decision-first',
  },
  { path: '', pathMatch: 'full', redirectTo: 'decision-first' },
  { path: 'callback', component: CallbackComponent },
  { path: 'loggedOut', component: LoggedOutComponent },
  { path: 'somethingWentWrong', component: RequestFailedMessageComponent },
  { path: 'sessionTimeout', component: SessionTimeoutComponent },
  { path: 'login', component: LoginContainerComponent, data: { displayVersionInformation: true }, canActivate: [LoginGuardService] },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
