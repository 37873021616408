import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from "@ngrx/store";
import { IndexedDBService, MessageService } from "core/services";
import { setFormAvailability } from 'core/utilities';
import { Subscription } from 'rxjs';
import { INameReference } from "core/config";
import { Guid } from "guid-typescript";
import { rootSelectors } from 'core/root-store';
import { untilDestroyed } from "ngx-take-until-destroy";
import { AuthenticatedUser } from "core/models";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "core-add-object",
  template: "",
})
export class AddObjectComponent implements OnDestroy {
  public formGroup: UntypedFormGroup;
  public addAction: any;
  public addActionSuccess: any;
  public addActionFailure: any;
  public addFieldToStateAction: any;
  public selectedTag: { tagId: string, parentTagId: string }
  relationObjectKey: any;
  relationObject: any;
  limitError: boolean = false;
  set isLoadingAddAction(value: boolean) {
    this._isLoadingAddAction = value;
  }
  get isLoadingAddAction(): boolean {
    return this._isLoadingAddAction;
  }
  private _isLoadingAddAction: boolean = false;
  isDisabled: boolean = false;
  subAddSuccessAction: Subscription;
  subAddFailureAction: Subscription;
  tagMetadata: { projectId: string, branchId: string, isMainBranch: boolean };
  authenticatedUser: AuthenticatedUser;
  objectTypeResourceName: string;

  constructor(
    private store: Store<unknown>,
    private dialogRef: NbDialogRef<unknown>,
    private actionsSubj$?: Actions,
    private messageService?: MessageService,
    private indexedDBService?: IndexedDBService,
  ) {
    this.store
      .pipe(select(rootSelectors.getAuthenticatedUser)).
      pipe(untilDestroyed(this)).subscribe(user => this.authenticatedUser = user);
  }
  ngOnDestroy() {
    if (this.subAddSuccessAction) {
      this.subAddSuccessAction.unsubscribe();
    }
    if (this.subAddFailureAction) {
      this.subAddFailureAction.unsubscribe();
    }
  }
  addObject(formValue?: any): void {
    formValue = formValue || this.formGroup.value;
    if (this.formGroup.valid) {
      this.isLoadingAddAction = true;
      this.isDisabled = true;
      setFormAvailability(this.formGroup, this.isDisabled);
      this.store.dispatch(new this.addAction(formValue));
      if (!this.addActionSuccess) {
        this.dialogRef.close();
      }
      if (this.addActionSuccess) {
        this.subAddSuccessAction = this.actionsSubj$.pipe(
          ofType(this.addActionSuccess)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.dialogRef.close();
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
      if (this.addActionFailure) {
        this.subAddFailureAction = this.actionsSubj$.pipe(
          ofType(this.addActionFailure)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
          if (data.payload.error.userMessage === 'Failed to create user because number of user to be created for an account has exceed') {
            // this.messageService.showError(data.payload, 'Number of user has exceeded!!');
            this.limitError = true;
          } else if (data.payload) {
            this.messageService.showError(data.payload, 'Error!!')
            this.dialogRef.close();
          }
        });
      }
    }
  }

  async addObjectWithTag(objectType?: string, checkNameReference: boolean = true) {
    if (this.formGroup.valid && this.tagMetadata?.projectId && 'isMainBranch' in this.tagMetadata) {
      this.isLoadingAddAction = true;
      this.isDisabled = true;
      setFormAvailability(this.formGroup, this.isDisabled);
      let nameReferences: INameReference[] = [];
      let newObject = { ...this.formGroup.value, id: Guid.create().toString(), referenceId: this.tagMetadata.isMainBranch ? "" : Guid.create().toString() };
      if (checkNameReference) {
        let { updatedName, nameReference: objectNameReference } = await this.indexedDBService.createMinimalNameReference(newObject.name, objectType, this.objectTypeResourceName, this.tagMetadata.projectId, undefined, this.authenticatedUser.accountId);
        objectNameReference.referenceId = newObject.referenceId || newObject.id;
        newObject.nameReference = objectNameReference;
        nameReferences.push({ ...objectNameReference, customerId: this.authenticatedUser.accountId, projectId: this.tagMetadata.projectId, lastModified: new Date().toISOString().slice(0, -1) } as INameReference);
        let fieldNameReference: Partial<INameReference>;
        let minimalField;
        if (objectType.toLowerCase() == 'decision') {
          let result = await this.indexedDBService.createMinimalNameReference(`t_${newObject.name}`, 'field', 'attribute', this.tagMetadata.projectId, undefined, this.authenticatedUser.accountId, false);
          fieldNameReference = result.nameReference;
          minimalField = {
            id: Guid.create().toString(), type: 'Any', name: result.updatedName,
            referenceId: this.tagMetadata.isMainBranch ? "" : Guid.create().toString()
          };
          fieldNameReference.referenceId = minimalField.referenceId || minimalField.id;
          nameReferences.push({ ...fieldNameReference, customerId: this.authenticatedUser.accountId, projectId: this.tagMetadata.projectId, lastModified: new Date().toISOString().slice(0, -1) } as INameReference);
          if (this.addFieldToStateAction) this.store.dispatch(new this.addFieldToStateAction([{
            ...minimalField, objectType: 'field', isList: false,
            isEnumeration: false, hasRange: false, defaultValue1: "", defaultValue2: ""
          }]));
          newObject.field = { ...minimalField, nameReference: fieldNameReference as any };
        }
        await this.indexedDBService.setToNameReferenceTable(nameReferences);
        newObject.name = updatedName;
      }
      this.store.dispatch(new this.addAction({ body: newObject, relationObjectKey: this.relationObjectKey, relationObject: this.relationObject, tagMetadata: { ...this.selectedTag, ...this.tagMetadata } }));
      if (!this.addActionSuccess) {
        this.dialogRef.close();
      }
      if (this.addActionSuccess) {
        this.subAddSuccessAction = this.actionsSubj$.pipe(
          ofType(this.addActionSuccess)
        ).subscribe(data => {
          this.isLoadingAddAction = false;
          this.dialogRef.close();
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
      if (this.addActionFailure) {
        this.subAddFailureAction = this.actionsSubj$.pipe(
          ofType(this.addActionFailure)
        ).subscribe(async data => {
          if (nameReferences?.length > 0) await this.indexedDBService.removeNameReferenceByIds(nameReferences.map(item => item.id));
          this.isLoadingAddAction = false;
          this.isDisabled = false;
          setFormAvailability(this.formGroup, this.isDisabled)
        });
      }
    }
  }
}
